<template>
  <div id="app" class="full-screen">
    <div id="map" ref="mapContainer" class="map-container"></div>
    <footer class="footer">
      <div class="footer-content">
        JUISTREET
      </div>
    </footer>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

export default {
  name: 'MapComponent',
  data() {
    return {
      map: null
    };
  },
  mounted() {
    this.initializeMap();
  },
  methods: {
    initializeMap() {
      mapboxgl.accessToken = 'pk.eyJ1IjoiZXJpY3BlZHJvemFhIiwiYSI6ImNsemxxdjY4eTA1M3AyaW9jcTg4Y3dwbGcifQ.KNKal1oY0ttoon-pL822Og'; // Substitua pelo seu token do Mapbox

      this.map = new mapboxgl.Map({
        container: this.$refs.mapContainer,
        style: 'mapbox://styles/ericpedrozaa/cm0wh621500aq01nt9y9w3mu0', // Seu estilo do Mapbox Studio
        center: [-51.1797, -29.1682], // Longitude primeiro, depois Latitude
        zoom: 9
      });

      this.map.on('load', () => {
    const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl
    });
    this.map.addControl(geocoder, 'top-right');
    this.map.addControl(new mapboxgl.NavigationControl(), 'top-right');

    const layers = this.map.getStyle().layers;

    layers.forEach(layer => {
        if (['symbol', 'fill'].includes(layer.type)) {  // Adiciona o tipo 'fill' para polígonos
            this.map.on('click', layer.id, (e) => {
                let coordinates;
                if (layer.type === 'fill') {
                    // Para polígonos, pegar o primeiro conjunto de coordenadas do primeiro anel
                    coordinates = e.features[0].geometry.coordinates[0][0].slice(); 
                } else {
                    // Para pontos, pegar diretamente as coordenadas
                    coordinates = e.features[0].geometry.coordinates.slice();
                }
                
                const description = e.features[0].properties.name || 'Nome não disponível';
                const horario = e.features[0].properties.opening_hours || e.features[0].properties.horario || 'Horário não disponível';
                const information = e.features[0].properties.description || 'Descrição não disponível';  // Adiciona description

                // Criar o HTML condicionalmente com description
                let popupContent = `<h4>${description}</h4>`;
                popupContent += `<p>Horário: ${horario}</p>`;
                popupContent += `<p>Descrição: ${information}</p>`;  // Adiciona campo de descrição

                new mapboxgl.Popup()
                    .setLngLat(coordinates)
                    .setHTML(popupContent)
                    .addTo(this.map);
            });

            this.map.on('mouseenter', layer.id, () => {
                this.map.getCanvas().style.cursor = 'pointer';
            });

            this.map.on('mouseleave', layer.id, () => {
                this.map.getCanvas().style.cursor = '';
            });
        }
    });
});

    }
  },
  beforeUnmount() {
    if (this.map) this.map.remove();
  }
};
</script>

<style scoped>
.full-screen {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 97vh;
  background-color: black;
  display: flex;
  flex-direction: column;
}

.map-container {
  flex-grow: 1;
  width: 100%;
  height: calc(100vh - 60px);
  background-color: black;
}

.footer {
  width: 100%;
  height: 3vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-content {
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: black;
}
</style>